import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
// import isNavActive from '../utils/is-nav-active'
import { BlockWrapper } from '../components/MainBlocks'
import { Wordmark } from '../components/Logo'

const PrimaryFooter = props => {
  // const items = props.items
  let footerListClasses = 'block list-style-none m0 p0'
  let footerLinkClasses = 'p1 line-height-2 inline-block black'
  let year = new Date().getFullYear()

  return (
    <footer className="PrimaryFooter bg-silver black pb3 md-pt3 md-pb4">
      <BlockWrapper maxWidth={6} bg="silver">
        <div className="sm-flex md-mxn1 col-12">
          <div className="md-px1 col-12 sm-col-2">
            <Link to="/">
              <Wordmark style={{ maxWidth: '140px' }} />
            </Link>
          </div>
          <div className="sm-flex my2 sm-my0 md-px1 flex-wrap justify-between col-12 sm-col-10">
            {/* columnNav */}
            <div />
            <div className="max-width-1" style={{ minHeight: '150px' }}>
              <ul className={footerListClasses}>
                <StaticQuery
                  query={graphql`
                    {
                      footerMenu: wpMenu(slug: { eq: "footer" }) {
                        # databaseId
                        menuItems {
                          nodes {
                            databaseId
                            path
                            nodeType
                            label
                            order
                            target
                          }
                        }
                      }
                    }
                  `}
                  render={data => {
                      return data.footerMenu.menuItems.nodes.map((node) => {
                        let targetProps = {}
                        if (node.target === '_blank') {
                          targetProps = {
                            target: node.target,
                            rel: 'noopener noreferrer',
                          }
                        }

                        return (
                          <li key={`Footer_${node.id}`}>
                            <Link
                              to={node.path}
                              className={footerLinkClasses}
                              {...targetProps}>
                              {node.label}
                            </Link>
                          </li>
                        )
                      })
                    
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
        <div className="PrimaryFooter-copyright sm-mt4 md-mt0 col-12 sm-flex mxn1 sm-mxn2">
          <div className="px2 py1 sm-py0 sm-border-right">
            Copyright © {year}{' '}
            <Link className="black" to="/">
              Cygnus Design Group
            </Link>
          </div>
          <div className="px2 py1 sm-py0">
            <Link className="black" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </BlockWrapper>
    </footer>
  )
}

PrimaryFooter.propTypes = {
  handle: PropTypes.string.isRequired,
  socialNetworks: PropTypes.array.isRequired,
}

PrimaryFooter.defaultProps = {
  socialNetworks: ['Facebook', 'Twitter', 'Instagram'],
}

export default PrimaryFooter
