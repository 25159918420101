import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isNavActive from '../utils/is-nav-active'
import { Wordmark } from '../components/Logo'
import { BlockWrapper } from '../components/MainBlocks'
import NavItem from '../components/PrimaryNavItem'
import InlineArrow from '../components/InlineArrow'
import { MenuOpen, MenuClose } from '../components/Icons'

class PrimaryNav extends Component {
  constructor() {
    super()

    this.state = {
      navWrapperWidth: '568px', // Magic number, gets calculated on mount
    }

    this.detailsEls = {}
    this.closeAllExpandedNavs = this.closeAllExpandedNavs.bind(this)
  }

  setNavWrapperWidth() {
    // Set width of navWrapper for details/summary offset
    if (window && this.navWrapper) {
      // TODO Change to document ready
      window.setTimeout(() => {
        let el = window.getComputedStyle(this.navWrapper)
        if (el) {
          let width = el.getPropertyValue('width')
          this.setState({ navWrapperWidth: parseFloat(width, 10) })
        }
      }, 500)
    }
  }

  componentDidMount() {
    this.setNavWrapperWidth()
  }

  closeAllExpandedNavs() {
    if (this.detailsEls) {
      let details = Object.keys(this.detailsEls)

      details.forEach(elKey => {
        let el = this.detailsEls[elKey]
        // console.log('closeAllExpandedNavs', el, el.removeAttribute(''))
        if (el && typeof el.removeAttribute === 'function') {
          el.removeAttribute('open')
        }
      })
    }
  }

  // This is a clunky function, but it’s still much improved over
  // the previous iteration, and preserves support for nested navs
  // —although the design is still intended for the two column
  // Our Expertise subnav more than anything else.
  renderChildItemsChildren(parent, keyPrefix, navItemProps) {
    if (
      !parent ||
      !parent.childItems ||
      !parent.childItems.nodes ||
      !parent.childItems.nodes.length
    ) {
      return null
    }

    return (
      <ul className="m0 p0 list-style-none mxn1">
        {parent.childItems.nodes.map((item, index) => {
          return (
            <NavItem
              activeClass="magneta PrimaryNav-active"
              key={`${keyPrefix}_${index}`}
              {...item}
              {...navItemProps}
              active={isNavActive(navItemProps.pathname, item.path)}
            />
          )
        })}
      </ul>
    )
  }

  renderChildItems(parent, keyPrefix, style, navItemProps) {
    if (
      !parent ||
      !parent.childItems ||
      !parent.childItems.nodes ||
      !parent.childItems.nodes.length
    ) {
      return null
    }

    return (
      <ul className="m0 p0 list-style-none flex col-12 right" style={style}>
        {parent.childItems.nodes.map((childItem, index) => {
          let keyStr = `${keyPrefix}_${index}`
          return (
            <li key={keyStr} className="flex-auto px1">
              <strong>{childItem.label}</strong>
              {this.renderChildItemsChildren(childItem, keyStr, navItemProps)}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    const props = this.props
    const items = props.menuItems.nodes

    // TODO Probably change this to SVG
    let burgerIcon = props.burgerisOpen ? <MenuClose /> : <MenuOpen />
    let navItemClassName = 'xs-hide sm-hide px1 black'

    return (
      <nav className="PrimaryNav bg-white py2">
        <BlockWrapper maxWidth={6} py={false}>
          <div className="flex col-12 justify-between list-style-none m0 p0">
            <div>
              <NavItem
                is="div"
                activeClass="magneta PrimaryNav-active"
                path="/"
                onClick={this.closeAllExpandedNavs}
                onBlur={this.closeAllExpandedNavs}>
                <Wordmark
                  style={{
                    maxWidth: '150px',
                    // maxHeight: '60px',
                    transform: 'translateY(25%)', // Align to baseline
                  }}
                />
              </NavItem>
            </div>
            <ul
              className="m0 p0 list-style-none flex items-end"
              ref={el => (this.navWrapper = el)}>
              {items.map((item, index) => {
                let active = isNavActive(props.pathname, item.path)
                let keyStr = `PrimaryNav_Item_${index}`

                if (item.parentDatabaseId && item.parentDatabaseId !== 0) {
                  return null
                }

                if (
                  item.childItems &&
                  item.childItems.nodes &&
                  item.childItems.nodes.length
                ) {
                  return (
                    <details
                      key={keyStr}
                      ref={el => {
                        this.detailsEls[keyStr] = el
                      }}
                      className={`PrimaryNav-details ${navItemClassName}`}>
                      <summary className="cursor-pointer">
                        {item.label}
                        &nbsp;
                        <InlineArrow />
                      </summary>
                      <div
                        className="mt2 black pt1 px2 lg-px3 pb3 flex-none absolute bg-white z4"
                        style={{
                          boxShadow: '0 4px 2px rgba(0, 0, 0, 0.1)',
                          width: '100%',
                          right: 0,
                          // transform: `translateX(-560px)`,
                        }}>
                        {this.renderChildItems(
                          item,
                          keyStr,
                          {
                            width: this.state.navWrapperWidth,
                          },
                          {
                            onClick: this.closeAllExpandedNavs,
                            className: navItemClassName,
                            pathname: props.pathname,
                          }
                        )}
                      </div>
                    </details>
                  )
                }

                return (
                  <React.Fragment key={keyStr}>
                    <NavItem
                      is="li"
                      activeClass="magneta PrimaryNav-active"
                      onClick={this.closeAllExpandedNavs}
                      onBlur={this.closeAllExpandedNavs}
                      className={navItemClassName}
                      key={keyStr}
                      {...item}
                      active={active}
                    />
                  </React.Fragment>
                )
              })}
            </ul>
            <div
              className={`md-hide lg-hide ${
                props.burgerisOpen ? 'fixed white' : 'absolute black'
              } top-0 right-0 sm-pr2`}
              style={{
                zIndex: 1200,
                top: '1.1rem', // Align to baseline
              }}>
              <div>
                <button
                  className="BurgerButton btn h2"
                  onClick={props.onClickBurger}
                  tabIndex={props.burgerisOpen ? 1 : 0}
                  style={{ width: '75px', height: 'auto' }}>
                  {burgerIcon}
                </button>
              </div>
            </div>
          </div>
        </BlockWrapper>
      </nav>
    )
  }
}

PrimaryNav.propTypes = {
  items: PropTypes.array.isRequired,
}

PrimaryNav.defaultProps = {
  items: [],
}

export default PrimaryNav
