import React from 'react'
import PropTypes from 'prop-types'

const InlineArrow = props => (
  <span className={`InlineArrow speak-none ${props.color}`} role="presentation">
    »
  </span>
)

InlineArrow.defaultProps = {
  color: 'inherit',
}

InlineArrow.propTypes = {
  color: PropTypes.string.isRequired,
}

export default InlineArrow
