import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Button = props => {
  let color = props.color
  let classes = `inline-block btn btn-border line-height-2 py1 px${props.px} border-${color} ${color} uppercase bold`

  let Tag = props.as
  if (props.href) {
    Tag = 'a'
  } else if (props.to) {
    Tag = Link
  }

  return <Tag {...props} className={classes} />
}

Button.defaultProps = {
  as: 'button',
  color: 'black',
  px: 2,
}

Button.propTypes = {
  as: PropTypes.oneOf(['a', 'button', 'input', 'div']).isRequired,
  color: PropTypes.oneOf(['black', 'white', 'blue', 'magenta', 'yellow'])
    .isRequired,
  px: PropTypes.number.isRequired,
}

export default Button
