// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-in-progress-js": () => import("./../../../src/pages/in-progress.js" /* webpackChunkName: "component---src-pages-in-progress-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-wp-expertise-js": () => import("./../../../src/templates/WPExpertise.js" /* webpackChunkName: "component---src-templates-wp-expertise-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WPPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WPPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "component---src-templates-wp-project-js": () => import("./../../../src/templates/WPProject.js" /* webpackChunkName: "component---src-templates-wp-project-js" */)
}

