import React from 'react'
import PropTypes from 'prop-types'
import NukaCarousel from 'nuka-carousel'
import ImgSharp from './ImgSharp'
import { LeftArrow, RightArrow } from './Icons'

const ArrowButton = props => {
  let right = props.right

  return (
    <button
      onClick={props.onClick}
      aria-label={right ? 'Right' : 'Left'}
      style={{ height: '100%' }}
      className={`btn ArrowButton bg-transparent col-3 border-none appearance-none ${
        right ? 'right' : 'left'
      } ${props.className}`}>
      {right ? <RightArrow /> : <LeftArrow />}
    </button>
  )
}

ArrowButton.defaultProps = {}
ArrowButton.propTypes = {
  right: PropTypes.bool,
  left: PropTypes.bool,
  className: PropTypes.string,
}

// MIT via https://github.com/FormidableLabs/nuka-carousel/blob/main/src/default-controls.js
class PagingDots extends React.Component {
  getIndexes(count, inc) {
    const arr = []
    for (let i = 0; i < count; i += inc) {
      arr.push(i)
    }
    return arr
  }

  render() {
    const indexes = this.getIndexes(
      this.props.slideCount,
      this.props.slidesToScroll
    )

    return (
      <div className="PagingDots">
        <ul className="m0 p0 pt1 relative col-12 flex justify-center">
          {indexes.map(index => {
            return (
              <li className="list-style-none inline-block" key={index}>
                <button
                  aria-label={`Slide ${index}`}
                  className={`PagingDots-btn ${
                    this.props.currentSlide === index ? '' : 'muted'
                  } btn border-none bg-transparent black cursor-pointer line-height-1 py0 px2 md-px1 h4`}
                  onClick={this.props.goToSlide.bind(null, index)}>
                  <div
                    style={{ width: '5px', height: '5px' }}
                    className="PagingDots-dot bg-black circle"
                  />
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

class Carousel extends React.Component {
  constructor() {
    super()

    this.carousel = null
  }

  componentDidMount() {
    // Fixes issue with Nuka and SSR:
    // https://github.com/FormidableLabs/nuka-carousel/issues/103#issuecomment-464408073
    // https://github.com/FormidableLabs/nuka-carousel/issues/137
    if (this.carousel) {
      setTimeout(() => {
        this.carousel.setDimensions()
      }, 0)
    }
  }

  render() {
    const { gallery, children, ...carouselProps } = this.props

    if (gallery || children) {
      if (!children && gallery.length === 1) {
        let image = gallery[0]
        return <ImgSharp {...image} useGatsbyImage={false} />
      } else if (children || gallery.length >= 2) {
        return (
          <NukaCarousel
            ref={carousel => {
              this.carousel = carousel
            }}
            dragging
            wrapAround
            renderCenterLeftControls={({ previousSlide }) => (
              <ArrowButton left onClick={previousSlide}>
                <LeftArrow />
              </ArrowButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ArrowButton right onClick={nextSlide}>
                <RightArrow />
              </ArrowButton>
            )}
            renderBottomCenterControls={props => <PagingDots {...props} />}
            {...carouselProps}>
            {gallery
              ? gallery.map((image, index) => {
                  return (
                    <ImgSharp
                      useGatsbyImage={false}
                      key={`${image.id}_${index}`}
                      {...image}
                      className="block m0 col-12 select-none"
                    />
                  )
                })
              : children}
          </NukaCarousel>
        )
      }
    }

    return null
  }
}

Carousel.defaultProps = {
  gallery: null,
  children: null,
  framePadding: '0 0 50px 0',
  dragging: true,
  wrapAround: true,
}

Carousel.propTypes = {
  gallery: PropTypes.array,
  children: PropTypes.array,
}

export { Carousel, ArrowButton }
