import React from 'react'
import PropTypes from 'prop-types'
// import { StaticQuery, graphql } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'

// Ours
import isNavActive from '../utils/is-nav-active'
import NavItem from '../components/PrimaryNavItem'
import InlineArrow from '../components/InlineArrow'

class MenuDetails extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window && this.element) {
      if (this.props.open === true) {
        this.element.setAttribute('open', 'open')

        // TODO This could be less DOM-specific, selects <ul /> in <summary />
        this.element.children[1].focus()
      } else {
        this.element.removeAttribute('open')
      }
    }
  }

  render() {
    return (
      <details
        className="PrimaryNav-details"
        ref={el => {
          this.element = el
        }}>
        {this.props.children}
      </details>
    )
  }
}

MenuDetails.defaultProps = {
  open: false,
}

MenuDetails.propTypes = {
  open: PropTypes.bool.isRequired,
}

class PrimaryMenu extends React.Component {
  constructor() {
    super()

    this.state = {
      openSubNavId: false,
    }
  }

  showSettings(e) {
    e.preventDefault()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Possibly not necessary anymore?
    // This will focus on the ul even when re-rendering
    // from changing details/summary
    // if (prevProps.isOpen === true) {
    //   this.ul.focus()
    // }
  }

  renderChildItemsChildren(parent, keyPrefix, navItemProps) {
    if (
      !parent ||
      !parent.childItems ||
      !parent.childItems.nodes ||
      !parent.childItems.nodes.length
    ) {
      return null
    }

    return (
      <ul className="list-style-none m0 p0">
        {parent.childItems.nodes.map((childItem, index) => {
          let activeSubItem = isNavActive(navItemProps.pathname, childItem.path)

          return (
            <NavItem
              {...navItemProps}
              className={`${navItemProps.className} py1 line-height-2 h4`}
              key={`${keyPrefix}_${index}`}
              {...childItem}
              active={activeSubItem}
            />
          )
        })}
      </ul>
    )
  }

  renderChildItems(parent, keyPrefix, navItemProps) {
    if (
      !parent ||
      !parent.childItems ||
      !parent.childItems.nodes ||
      !parent.childItems.nodes.length
    ) {
      return null
    }

    return (
      <React.Fragment>
        {parent.childItems.nodes.map((child, index) => {
          let keyStr = `${keyPrefix}_${index}`
          let openDetails = this.state.openSubNavId === keyStr

          if (
            child.childItems &&
            child.childItems.nodes &&
            child.childItems.nodes.length
          ) {
            return (
              <li key={keyStr}>
                <MenuDetails open={openDetails}>
                  <summary
                    onClick={e => {
                      e.preventDefault()
                      if (this.state.openSubNavId === keyStr) {
                        this.setState({ openSubNavId: false })
                      } else {
                        this.setState({ openSubNavId: keyStr })
                      }
                    }}
                    className={`${navItemProps.className} ${
                      openDetails ? 'muted' : ''
                    } cursor-pointer select-none py2`}>
                    <div>
                      {child.label}
                      &nbsp;
                      <InlineArrow />
                    </div>
                  </summary>
                  {this.renderChildItemsChildren(child, keyStr, navItemProps)}
                </MenuDetails>
              </li>
            )
          }

          // TODO Handle second level menu, with no children
          return <li key={keyStr}>{child.label}</li>
        })}
      </React.Fragment>
    )
  }

  render() {
    const props = this.props
    let items = props.menuItems.nodes
    let navClasses = 'white font-weight-200 h2 flex'
    let menuId = 'js-menu-page'
    let menuContainerId = 'js-menu-container'

    return (
      <div id={menuContainerId}>
        <Menu
          right
          width="100%"
          isOpen={props.isOpen}
          onStateChange={props.onStateChange}
          pageWrapId={menuId}
          outerContainerId={menuContainerId}>
          <ul
            className="PrimaryNav-details PrimaryNav-details--burger list-style-none m0 p0 flex flex-column pb1"
            style={{ minHeight: '100%' }}
            ref={el => (this.ul = el)}
            tabIndex="-1">
            {items.map((item, index) => {
              let active = isNavActive(props.pathname, item.url)
              let keyStr = `MenuWrapper_${index}`

              if (item.parentDatabaseId && item.parentDatabaseId !== 0) {
                return null
              }

              if (
                item.childItems &&
                item.childItems.nodes &&
                item.childItems.nodes.length
              ) {
                return (
                  <React.Fragment key={keyStr}>
                    {this.renderChildItems(item, keyStr, {
                      className: navClasses,
                      pathname: props.pathname,
                      tabIndex: props.isOpen ? 0 : -1,
                      onClick: props.onClick,
                      onBlur:
                        index === items.length - 1
                          ? e => {
                              this.ul.focus()
                            }
                          : null,
                    })}
                  </React.Fragment>
                )
              }

              return (
                <NavItem
                  tabIndex={props.isOpen ? 0 : -1}
                  className={`${navClasses} py2`}
                  key={`MenuWrapper_Item_${index}`}
                  onClick={props.onClick}
                  onBlur={
                    index === items.length - 1
                      ? e => {
                          this.ul.focus()
                        }
                      : null
                  }
                  {...item}
                  active={active}
                />
              )
            })}
          </ul>
        </Menu>
        <div id={menuId} tabIndex={props.isOpen ? -1 : 0}>
          {props.children}
        </div>
      </div>
    )
  }
}

PrimaryMenu.defaultProps = {
  resizeTimeout: 2000,
  isOpen: false,
  menuItems: {
    nodes: [],
  },
}

PrimaryMenu.propTypes = {
  resizeTimeout: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default PrimaryMenu
