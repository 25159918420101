import React from 'react'

// Ours
import Image from './Image'
import { Carousel } from '../Carousel'
import ImgSharp from '../ImgSharp'

class Gallery extends React.Component {
  render() {
    const { gallery, ...imageProps } = this.props

    if (gallery && Array.isArray(gallery) && gallery.length === 2) {
      return (
        <Image {...imageProps}>
          <div className="flex mxn1">
            {gallery.map((image, imageIndex) => {
              return (
                <div className="col-6 px1">
                  <ImgSharp {...image} />
                </div>
              )
            })}
          </div>
        </Image>
      )
    }

    return (
      <Image {...imageProps}>
        <div className="bg-silver">
          <Carousel gallery={gallery} />
        </div>
      </Image>
    )
  }
}

export default Gallery
