import React from 'react'
import PropTypes from 'prop-types'

const WordmarkGroup = props => {
  // By default, set the official colors
  let letterFill = { fill: '#000' }
  let dotsFill = { fill: '#b9006e' }

  // If color name is defined, we will use the CSS color
  // utility classes to change the colour of the whole mark
  if (props.color) {
    let obj = { className: `fill-${props.color}` }
    letterFill = obj
    dotsFill = obj
  }

  return (
    <g id="a1909437-5c7d-4d9e-9f37-fc6681cc8d58">
      <g {...letterFill}>
        <path d="M56,41l-8.75-25.9H40.55L52.68,48.49l-1.53,4.29c-1.47,4-3.2,5.08-5.84,5.08a14.38,14.38,0,0,1-3.61-.43v5.14a10.88,10.88,0,0,0,3.92.62c7,0,9.62-4.66,11.7-10.41L71,15.12H64.62Z" />
        <path d="M19.11,8.75c7.28,0,10.53,4.65,11,9.61h6.37C35.89,8.75,29.09,3.3,18.92,3.3,7.17,3.3,0,11.38,0,25.83s7.17,22.6,18.92,22.6c10.17,0,17-5.76,17.52-15.07H30.13c-.43,5-3.74,9.5-11,9.5-8.52,0-12.62-6.25-12.62-17S10.59,8.75,19.11,8.75Z" />
        <path d="M191.67,28.46l-4.41-.91c-2.88-.62-4.9-1.53-4.9-4.11s1.72-4.28,5.21-4.28c4,0,5.94,2.32,6.12,5.45h5.82c-.37-6.92-5-10.23-11.82-10.23-7.1,0-11.27,4-11.27,9.37s3.62,8.08,8.15,9.06l4.59,1c3.19.68,4.9,2,4.9,4.84,0,3.12-2,5-6,5-4.72,0-6.55-2.39-6.68-5.75h-6c.37,7.22,5.27,10.53,12.56,10.53C195.65,48.43,200,43.84,200,38,200,32.38,196.69,29.51,191.67,28.46Z" />
        <path d="M163.5,39.92A10.2,10.2,0,0,1,155.79,43c-5.39,0-6.68-3.18-6.68-9.25V15.12h-6.18v20c0,11,5.63,13.29,11,13.29,5.57,0,8.82-2.27,10.65-5v4.22h5.15V15.12H163.5Z" />
        <path d="M92.71,42.73h-8c-3.55,0-5.08-.73-5.08-2.51,0-1.53,1.35-2.69,2.51-3.3a16.32,16.32,0,0,0,5.57,1c7.47,0,13.41-4.41,13.41-11.76S95.22,14.38,87.75,14.38,74.34,18.73,74.34,26.14a10.71,10.71,0,0,0,4.35,9.06,6.79,6.79,0,0,0-4.29,6,5.91,5.91,0,0,0,3.86,5.51c-2.76,1.23-5.45,3.56-5.45,7.29,0,5.51,5.21,9.19,15.19,9.19,10.71,0,15.92-4.66,15.92-11.27C103.92,45.92,99.88,42.73,92.71,42.73Zm-5-23.57c4.59,0,7.41,2.63,7.41,7s-2.82,6.92-7.41,6.92-7.47-2.64-7.47-6.92S83.1,19.16,87.75,19.16Zm.43,39.31C81,58.47,78.69,56,78.69,53a5.18,5.18,0,0,1,3.86-4.84h8.94c4.77,0,6.43,1.35,6.43,4.41C97.92,56.33,94.73,58.47,88.18,58.47Z" />
        <path d="M124.49,14.38c-5.63,0-8.94,2.2-10.71,4.9V15.12h-5.21V47.69h6.19V22.89a10.16,10.16,0,0,1,7.78-3.12c5.57,0,6.85,3.18,6.85,9.12v18.8h6.19V27.61C135.58,16.58,129.94,14.38,124.49,14.38Z" />
      </g>
      <g {...dotsFill}>
        <path d="M104.06,11.58A4.08,4.08,0,1,1,100,7.5a4.08,4.08,0,0,1,4.08,4.08" />
        <path d="M111.56,4.08A4.08,4.08,0,1,1,107.48,0a4.08,4.08,0,0,1,4.08,4.08" />
      </g>
    </g>
  )
}

WordmarkGroup.propTypes = {
  color: PropTypes.string,
}

WordmarkGroup.defaultProps = {}

const Wordmark = props => {
  return (
    <svg viewBox="0 0 200 63.19" className="col-12" {...props}>
      <title>Cygnus</title>
      <WordmarkGroup {...props} />
    </svg>
  )
}

Wordmark.propTypes = {
  color: PropTypes.string,
}

Wordmark.defaultProps = {}

export { Wordmark, WordmarkGroup }
