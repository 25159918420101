import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  lang,
  meta,
  title,
  date,
  featuredImage,
  children,
}) {
  const query = graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
        acfOptions {
          acfOptions {
            ogUrl

            # TODO Decide whether or not to always use the default
            #      social media image, or whether to only pass it in
            #      as a fallback on the front page instead. I think
            #      always using it is probably more expected.
            ogImage {
              localFile {
                extension
                childImageSharp {
                  resize(width: 1920) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const renderWithQuery = data => {
    const defaultTitle = data.wp.generalSettings.title
    const baseUrl = data.wp.acfOptions.acfOptions.ogUrl
    title = title || defaultTitle
    description = description || data.wp.generalSettings.description
    featuredImage = featuredImage || data.wp.acfOptions.acfOptions.ogImage

    let defaultMeta = [
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: 'og:site_name',
        content: defaultTitle,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      // TODO Ask if we want to support this via WordPress users
      // {
      //   property: 'article:author',
      //   content: author.name,
      // },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:site`,
        content: `@cygnusgroup`, // TODO via GraphQL
      },
      {
        name: `twitter:creator`,
        content: `@cygnusgroup`, // TODO via GraphQL
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
      {
        // https://developer.twitter.com/en/docs/twitter-for-websites/privacy
        name: 'twitter:dnt',
        content: 'on',
      },
    ]

    if (date && typeof date === 'string') {
      defaultMeta.push({
        property: 'article:published_time',
        // TODO This could be handled via GraphQL in advance
        content: new Date(date),
      })
    }

    // Handle multiple object formats for featuredImages, namely
    // featuredImage.node.localFile from GraphQL, or passing in
    // localFile object, or passing in a hypothetical local image directly.
    if (featuredImage.node) {
      featuredImage = featuredImage.node
    }

    if (featuredImage.localFile) {
      featuredImage = featuredImage.localFile
    }

    if (
      featuredImage.childImageSharp &&
      (featuredImage.childImageSharp.fluid ||
        featuredImage.childImageSharp.resize)
    ) {
      let imgPath = featuredImage.childImageSharp.fluid
        ? featuredImage.childImageSharp.fluid.src
        : featuredImage.childImageSharp.resize.src
      let fullImgPath = `${baseUrl}${imgPath}`

      defaultMeta.push({
        property: 'og:image',
        content: fullImgPath,
      })

      if (featuredImage.extension) {
        defaultMeta.push({
          property: 'og:image:type',
          content: `image/${featuredImage.extension}`,
        })
      }

      defaultMeta.push({
        property: 'twitter:card',
        content: 'summary_large_image',
      })
      defaultMeta.push({
        property: 'twitter:image',
        content: fullImgPath,
      })
    }

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={
          title && title !== defaultTitle
            ? `%s • ${defaultTitle}`
            : defaultTitle
        }
        meta={defaultMeta.concat(meta)}>
        {children}
      </Helmet>
    )
  }

  return <StaticQuery query={query} render={renderWithQuery} />
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  date: PropTypes.string,
  featuredImage: PropTypes.oneOfType([
    PropTypes.shape({
      node: PropTypes.object,
    }),
    PropTypes.shape({
      childImageSharp: PropTypes.object,
    }),
    PropTypes.string,
  ]),
}

export default SEO
