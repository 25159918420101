// TODO Fix this in WordPress or gatsby-node.js
// Some only seem to be necessary for dev mode anyway

const urlAdjustments = function (url) {
  if (url) {
    if (url.includes('cygnus_expertise')) {
      url = url.replace('cygnus_expertise/', '')
    }

    if (url.includes('cygnus_')) {
      url = url.replace('cygnus_', '')
    }

    if (url.includes('projects')) {
      url = url.replace('projects', 'our-work')
    }

    // Turn into relative URL
    if (url.includes('http://')) {
      url = url.split('/')
      url.splice(0, 3, '')
      url = url.join('/')
    }
  }

  return url
}

module.exports = urlAdjustments
