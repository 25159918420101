import '../css/fonts.css'
import '../css/index.css'

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import throttle from 'lodash.throttle'

// Ours
import SEO from './SEO'
import PrimaryNav from '../components/PrimaryNav'
import PrimaryMenu from '../components/PrimaryMenu'
import PrimaryFooter from '../components/PrimaryFooter'

import fontPreloaded from '../css/fonts/nittigrotesk-normal-v20.woff'
import isDev from '../utils/is-dev'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      showBehindMenu: true,
      city: null,
      region: null,
      country: null,
    }

    this.handleResize = throttle(this.handleResize, 500)
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize() {
    if (isDev()) {
      console.log('resize')
    }
    if (this.state.menuOpen && window.matchMedia('(min-width: 54em)').matches) {
      this.setState({ menuOpen: false })
    }
  }

  componentDidMount() {
    if (typeof window.matchMedia !== 'undefined') {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.clearTimeout(this.timeoutShowBehindMenu)
  }

  handleStateChange(newState) {
    this.setState({ menuOpen: newState.isOpen })

    let menuTransitionDuration = 500
    if (typeof window !== 'undefined') {
      if (newState.isOpen === true) {
        this.timeoutShowBehindMenu = setTimeout(() => {
          this.setState({ showBehindMenu: false })
          window.clearTimeout(this.timeoutShowBehindMenu)
        }, menuTransitionDuration)
      } else {
        window.clearTimeout(this.timeoutShowBehindMenu)
        this.setState({ showBehindMenu: true })
      }
    }
  }

  render() {
    const props = this.props
    const children = props.children
    const pathname = props.location.pathname

    if (pathname === '/styleguide') {
      return children
    }

    return (
      <StaticQuery
        query={graphql`
          query SiteMetaQuery {
            wp {
              acfOptions {
                acfOptions {
                  handle
                  phoneNumber
                  ogUrl
                }
              }
            }
            primaryMenu: wpMenu(slug: { eq: "primary" }) {
              count
              databaseId
              name
              menuItems {
                nodes {
                  cssClasses
                  databaseId
                  path
                  nodeType
                  label
                  order
                  target
                  parentDatabaseId
                  childItems {
                    nodes {
                      databaseId
                      parentDatabaseId
                      label
                      path
                      childItems {
                        nodes {
                          databaseId
                          parentDatabaseId
                          label
                          path
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const options = data.wp.acfOptions.acfOptions
          let El = props.is

          return (
            <El className={props.className}>
              <SEO>
                <link
                  rel="preload"
                  href={fontPreloaded}
                  as="font"
                  type="font/woff2"
                  crossorigin
                />
              </SEO>
              <PrimaryMenu
                {...data.primaryMenu}
                onStateChange={newState => {
                  return this.handleStateChange(newState)
                }}
                onClick={e => {
                  this.setState({
                    menuOpen: false,
                  })
                }}
                pathname={pathname}
                isOpen={this.state.menuOpen}>
                <div className="md-min-height-100vh">
                  <header>
                    <PrimaryNav
                      {...data.primaryMenu}
                      pathname={pathname}
                      burgerisOpen={this.state.menuOpen}
                      onClickBurger={e => {
                        this.setState({
                          menuOpen: !this.state.menuOpen,
                        })
                      }}
                    />
                  </header>
                  <main
                    className={`PrimaryMenu-main--${
                      this.state.showBehindMenu ? 'show' : 'hide'
                    }`}>
                    {children}
                  </main>
                </div>
                <PrimaryFooter
                  pathname={pathname}
                  handle={options.handle}
                />
              </PrimaryMenu>
            </El>
          )
        }}
      />
    )
  }
}

Layout.defaultProps = {
  is: 'div',
}

Layout.propTypes = {
  // children: PropTypes.func,
  is: PropTypes.string,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
}

export default Layout
