import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// Ours
import urlAdjustments from '../utils/url-adjustments'
import unesc from '../utils/unescape'

const PrimaryNavItem = props => {
  let El = props.is
  // let hasChildren = props.items && props.items.length >= 1

  let path = urlAdjustments(props.path)

  return (
    <El>
      <Link
        className={`block select-none ${props.className} ${
          props.active ? props.activeClass : ''
        }`}
        to={path}
        tabIndex={props.tabIndex}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onClick={props.onClick}>
        {props.children || unesc(props.label)}
      </Link>
    </El>
  )
}

PrimaryNavItem.defaultProps = {
  is: 'li',
  onClick: null, // onClick can close the menu
  tabIndex: null,
  activeClass: 'muted',
  active: false,
  className: '',
}

PrimaryNavItem.propTypes = {
  is: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  activeClass: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node,

  // From WPGraphQL
  databaseId: PropTypes.number,
  label: PropTypes.string,
  nodeType: PropTypes.string,
  order: PropTypes.number,
  path: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
}

export default PrimaryNavItem
