import React from 'react'
import PropTypes from 'prop-types'
import typogr from 'typogr'

const Testimonial = props => {
  let Tag = props.is

  let quote = props.quote
  // TODO Fix this upstream in Typogr.js
  // Need to turn (correct) smart quotes into entities
  // for initial quotes function.
  quote = quote.replace('“', '&#8220;')
  quote = quote.replace('”', '&#8221;')
  quote = typogr(quote).typogrify()

  let org = null
  if (props.organization && typeof props.organization !== 'undefined') {
    org = typogr(props.organization).typogrify()
    org = (
      <span
        dangerouslySetInnerHTML={{
          __html: org,
        }}
      />
    )
  }

  let credit = null
  if (props.author && org) {
    credit = (
      <React.Fragment>
        <span>{props.author}</span>.&nbsp;{org}
      </React.Fragment>
    )
  } else if (props.author) {
    credit = <span>{props.author}</span>
  } else if (props.organization) {
    credit = org
  }

  return (
    <Tag className="m0 mb2">
      <span
        className={`${props.fontSize} line-height-3 font-weight-${props.quoteFontWeight} ${props.quoteColor}`}
        dangerouslySetInnerHTML={{
          __html: quote,
        }}
      />
      {props.author || props.organization ? (
        <footer
          className={`${props.footerFontSize} line-height-2 mt1 mb3 ${
            props.footerColor
          } ${props.inline ? 'inline' : 'block'}`}>
          —&nbsp;
          {credit}
        </footer>
      ) : null}
    </Tag>
  )
}

Testimonial.defaultProps = {
  is: 'blockquote',
  quoteColor: 'magenta',
  quoteFontWeight: 400,
  footerColor: '',
  inline: false,
  fontSize: 'h5 md-h4',
  footerFontSize: 'h5 md-h4',
}

Testimonial.propTypes = {
  is: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  quoteColor: PropTypes.string,
  quoteFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quote: PropTypes.string.isRequired,
  footerColor: PropTypes.string,
  author: PropTypes.string,
  organization: PropTypes.string,
}

export default Testimonial
