import React from 'react'
import PropTypes from 'prop-types'

// Ours
import BlockWrapper from './BlockWrapper'
import ImgSharp from '../ImgSharp'

const Image = props => {
  let caption = null
  let captionText = null
  let captionDetails = null
  let image = props.children ? props.children : <ImgSharp {...props.image} />

  if (props.captionText) {
    captionText = (
      <div
        className="py1"
        dangerouslySetInnerHTML={{
          __html: props.captionText,
          // .replace('<p><b>', '<h4>')
          // .replace('</b></p>', '</h4>'),
        }}
      />
    )
  }

  if (props.captionDetails) {
    captionDetails = (
      <ul className="list-style-none m0 p0 py1 sm-flex flex-wrap sm-mxn1">
        {props.captionDetails.map((detail, index) => {
          let keyStr = `${props.keyPrefix}_CaptionDetails_${index}`
          return (
            <li key={keyStr} className="block sm-col-6 sm-px1">
              <strong>{detail.labelCustom || detail.label}</strong>&nbsp;
              {detail.detail}
            </li>
          )
        })}
      </ul>
    )
  }

  if (captionText || captionDetails) {
    caption = (
      <figcaption className="gray px2 sm-px3 md-px3 lg-px4 py1">
        {captionDetails}
        {captionText}
      </figcaption>
    )
  }

  if (props.settings.showFullWidth) {
    return (
      <BlockWrapper
        namespace="MainBlocksImage"
        maxWidth={false}
        px={false}
        py={false}>
        <figure>
          {image}
          {caption}
        </figure>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper
        namespace="MainBlocksImage"
        maxWidth={5}
        px={props.sectionHeading ? false : undefined}>
        {/*
        {props.sectionHeading ? (
          <BlockWrapper maxWidth={3} py={false}>
            <div className="MainBlocksImage-heading pt2 sm-pt3 md-pt3 lg-pt3 pb2 md-pb3">
              <SectionHeadingOnly text={props.sectionHeading} />
            </div>
          </BlockWrapper>
        ) : null}
        */}
        <div className="mx-auto MainBlocksImage-container">
          <figure className="my0">
            <div className={props.sectionHeading ? '' : 'md-mxn2 lg-mxn3'}>
              {image}
            </div>
            {caption}
          </figure>
        </div>
      </BlockWrapper>
    )
  }
}

Image.defaultProps = {
  settings: {},
}

Image.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object.isRequired,
}

export default Image
