import React from 'react'
import PropTypes from 'prop-types'

const BlockWrapper = props => {
  let className = []

  if (props.maxWidth) {
    className.push(`max-width-${props.maxWidth} mx-auto`)
  }

  if (props.px) {
    let px = props.px
    className.push(`px${px} sm-px${px + 1} md-px${px + 1} lg-px${px + 2}`)
  }

  // NOTE There is less vertical than horizontal padding at the lg breakpoint
  if (props.py) {
    let py = props.py
    className.push(`py${py} sm-py${py + 1} md-py${py + 1} lg-py${py + 1}`)
  }

  className = className.join(' ')

  return (
    <div
      className={`${props.namespace} ${props.bg ? `bg-${props.bg}` : ''} ${
        props.color ? props.color : ''
      }`}>
      <div className={className}>{props.children}</div>
    </div>
  )
}

BlockWrapper.defaultProps = {
  namespace: '',
  bg: 'white',
  color: 'black',
  maxWidth: 5,
  px: 2,
  py: 2,
}

BlockWrapper.propTypes = {
  namespace: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6]).isRequired,
  px: PropTypes.oneOf([false, 0, 1, 2, 3]).isRequired,
  py: PropTypes.oneOf([false, 0, 1, 2, 3]).isRequired,
}

export default BlockWrapper
