module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"url":"https://cygnusgroup.kinsta.cloud/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":30000,"hardCacheData":false},"auth":{"htaccess":{"username":"api_user_read_all","password":"I7d0 yivY aD8T ZmR5 HIQ1 AkYo"}},"debug":{"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"Comment":{"exclude":true},"Tag":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"MediaItem":{"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout.js"},
    }]
