import React from 'react'
import PropTypes from 'prop-types'

// Blocks
import BlockWrapper from './BlockWrapper'

// Ours
import { LeftArrow, RightArrow } from '../Icons'
import { Carousel, ArrowButton } from '../Carousel'
import Testimonial from '../Testimonial'

const TestimonialSlide = props => {
  return (
    <BlockWrapper maxWidth={4} bg="transparent" color="white">
      <div className="min-height-50vh flex items-center">
        <Testimonial {...props} quoteColor="white" footerColor="muted" />
      </div>
    </BlockWrapper>
  )
}

const Testimonials = props => {
  if (!props.testimonials) {
    return null
  }

  let carousel = null

  if (props.testimonials.length === 1) {
    carousel = <TestimonialSlide {...props.testimonials[0]} />
  } else {
    carousel = (
      <Carousel
        heightMode="max"
        renderCenterLeftControls={({ previousSlide }) => (
          <ArrowButton
            left
            className="xs-hide sm-hide md-hide lg-show"
            onClick={previousSlide}>
            <LeftArrow />
          </ArrowButton>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <ArrowButton
            right
            className="xs-hide sm-hide md-hide lg-show"
            onClick={nextSlide}>
            <RightArrow />
          </ArrowButton>
        )}
        renderBottomLeftControls={({ previousSlide }) => (
          <ArrowButton
            left
            className="xs-show sm-show md-show lg-hide pb2"
            onClick={previousSlide}>
            <LeftArrow />
          </ArrowButton>
        )}
        renderBottomRightControls={({ nextSlide }) => (
          <ArrowButton
            right
            className="xs-show sm-show md-show lg-hide pb2"
            onClick={nextSlide}>
            <RightArrow />
          </ArrowButton>
        )}
        renderBottomCenterControls={null}>
        {props.testimonials.map((testimonial, testimonialIndex) => {
          let keyStr = `Testimonial_$${testimonialIndex}`
          return (
            <BlockWrapper key={keyStr} maxWidth={5} bg="transparent">
              <TestimonialSlide {...testimonial} />
            </BlockWrapper>
          )
        })}
      </Carousel>
    )
  }

  return <div className={`bg-${props.bg} ${props.color}`}>{carousel}</div>
}

Testimonials.defaultProps = {
  bg: 'navy',
  color: 'white',
}

Testimonials.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  testimonials: PropTypes.array.isRequired,
}

export default Testimonials
