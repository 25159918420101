import React from 'react'
import PropTypes from 'prop-types'

const ArrowWrapper = props => {
  return (
    <svg
      viewBox="0 0 150 300"
      className="col-12 block"
      style={{
        overflow: 'visible',
        height: '100%',
        maxHeight: props.maxHeight,
      }}>
      {props.children}
    </svg>
  )
}

ArrowWrapper.defaultProps = {
  maxHeight: 'auto',
}

ArrowWrapper.propTypes = {
  maxHeight: PropTypes.string,
}

const LeftArrow = props => {
  return (
    <ArrowWrapper title="Left Arrow" maxHeight="75px">
      <g fill={props.fill}>
        <circle cx="100" cy="50" r="9" />
        <circle cx="50" cy="100" r="9" />
        <circle cx="0" cy="150" r="9" />
        <circle cx="50" cy="200" r="9" />
        <circle cx="100" cy="250" r="9" />
      </g>
    </ArrowWrapper>
  )
}

LeftArrow.defaultProps = {
  fill: '#FFF',
}

LeftArrow.propTypes = {
  stroke: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeMiterlimit: PropTypes.number,
  strokeWidth: PropTypes.string,
  fill: PropTypes.string,
}

const RightArrow = props => {
  return (
    <ArrowWrapper title="Right Arrow" maxHeight="75px">
      <g fill={props.fill}>
        <circle cx="50" cy="50" r="9" />
        <circle cx="100" cy="100" r="9" />
        <circle cx="150" cy="150" r="9" />
        <circle cx="100" cy="200" r="9" />
        <circle cx="50" cy="250" r="9" />
      </g>
    </ArrowWrapper>
  )
}

RightArrow.defaultProps = {
  fill: '#FFF',
}

RightArrow.propTypes = {
  stroke: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeMiterlimit: PropTypes.number,
  strokeWidth: PropTypes.string,
  fill: PropTypes.string,
}

const MenuOpen = props => {
  return (
    <svg
      viewBox="0 0 38 38"
      className="col-12 block"
      style={{
        overflow: 'visible',
        height: '100%',
      }}>
      <title>Menu</title>
      <g id="7a73bad4-55fe-48ce-bc02-1ceb41918199" className="fill-black">
        <g>
          <circle cx="37" cy="4" r="1" />
          <circle cx="1" cy="4" r="1" />
          <rect x="1" y="3" width="36" height="2" />
        </g>
        <g>
          <circle cx="37" cy="19" r="1" />
          <circle cx="1" cy="19" r="1" />
          <rect x="1" y="18" width="36" height="2" />
        </g>
        <g>
          <circle cx="37" cy="34" r="1" />
          <circle cx="1" cy="34" r="1" />
          <rect x="1" y="33" width="36" height="2" />
        </g>
      </g>
    </svg>
  )
}

const MenuClose = props => {
  return (
    <svg
      viewBox="0 0 38 38"
      className="col-12 block"
      style={{
        overflow: 'visible',
        height: '100%',
      }}>
      <title>Menu</title>
      <g id="7a73bad4-55fe-48ce-bc02-1ceb41918199" className="fill-white">
        <g fill="transparent">
          <circle cx="37" cy="4" r="1" />
          <circle cx="1" cy="4" r="1" />
          <rect x="1" y="3" width="36" height="2" />
        </g>
        <g>
          <circle cx="37" cy="19" r="1" />
          <circle cx="1" cy="19" r="1" />
          <rect x="1" y="18" width="36" height="2" />
        </g>
        <g fill="transparent">
          <circle cx="37" cy="34" r="1" />
          <circle cx="1" cy="34" r="1" />
          <rect x="1" y="33" width="36" height="2" />
        </g>
      </g>
    </svg>
  )
}

export { LeftArrow, RightArrow, MenuOpen, MenuClose }
