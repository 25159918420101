import React from 'react'
import PropTypes from 'prop-types'
import slugify from 'slugify'

// Blocks
import Image from './MainBlocks/Image'
import Gallery from './MainBlocks/Gallery'
import BlockWrapper from './MainBlocks/BlockWrapper'
import Testimonials from './MainBlocks/Testimonials'

// Ours
import { H3, H4 } from './Headings'
import ImgSharp from './ImgSharp'
import Button from './Button'
import unesc from '../utils/unescape'
import urlAdjustments from '../utils/url-adjustments'

const SectionHeadingOnly = props => {
  if (!props.text) {
    return null
  }

  let id = props.id
  if (!id || typeof id === 'undefined') {
    id = props.text.split(' ', 5).join(' ')
    id = slugify(id, { lower: true, strict: true })
  }

  let className = `max-width-${props.maxWidth} ${props.mxAuto ? 'mx-auto' : ''}`

  return (
    <div className={className}>
      <H3
        fontWeight={600}
        lineHeight={3}
        is="h2"
        mt={0}
        mb={0}
        color="magenta"
        id={id}>
        {props.text}
      </H3>
    </div>
  )
}

SectionHeadingOnly.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  maxWidth: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5]).isRequired,
  mxAuto: PropTypes.bool.isRequired,
}

SectionHeadingOnly.defaultProps = {
  // Default is for shorter, wrapped headline
  maxWidth: 2,
  mxAuto: false,
}

const SectionHeading = props => {
  return (
    <BlockWrapper namespace="MainBlocksSectionHeading" maxWidth={3}>
      <SectionHeadingOnly mxAuto {...props} maxWidth={3} />
    </BlockWrapper>
  )
}

const ThreeColumn = props => {
  const showImages = props.settings.showImages

  let theme = {
    subheadColor: 'magenta',
    color: 'black',
    bg: 'transparent',
  }

  // TODO This might be the wrong interpretation—
  //      change the way themeing is set based
  //      on the post type, or do it in CSS for Insights?
  if (showImages) {
    theme = {
      subheadColor: 'yellow',
      color: 'white',
      bg: 'navy',
    }
  }

  return (
    <BlockWrapper
      namespace="MainBlocksThreeColumn"
      bg={theme.bg}
      px={showImages ? 0 : undefined}
      py={showImages ? 0 : undefined}
      maxWidth={showImages ? false : 4}>
      <div
        className={`md-flex bg-${theme.bg} ${theme.color} ${
          showImages ? '' : 'mxn2'
        }`}>
        {props.columns.map((col, index) => {
          let bgImageStyle = {}
          if (showImages && col.image) {
            bgImageStyle = {
              backgroundImage: `url(${col.image.localFile.childImageSharp.resize.src})`,
            }

            if (props.settings.showImagesStylized) {
              bgImageStyle.mixBlendMode = 'luminosity'
              bgImageStyle.filter = `saturate(0)`
            }
          }

          return (
            <div
              key={`${props.keyPrefix}_ThreeColumn_col_${index}`}
              className={`col-12 md-col-4 ${
                showImages ? 'flex flex-wrap' : ''
              }`}>
              {showImages && col.image ? (
                <figure
                  className={`block col-12 sm-col-6 md-col-12 md-min-height-50vh ${
                    index % 2 ? 'sm-order-last' : ''
                  }`}>
                  <div
                    style={bgImageStyle}
                    className="bg-cover col-12 height-50vh sm-height-100"
                  />
                </figure>
              ) : null}

              <div
                className={
                  showImages
                    ? `px2 sm-px3 md-px3 lg-px4 pt3 ${
                        col.bigNumber ? '' : 'md-pt4 lg-pt4'
                      } h5 md-h6 lg-h5 col-12 sm-col-6 md-col-12 min-height-50vh md-flex`
                    : 'px2'
                }>
                <div
                  className={
                    showImages ? 'block max-width-2' : 'flex md-block'
                  }>
                  {col.bigNumber ? (
                    <figure
                      className={`flex-auto ${
                        showImages ? 'left-align' : 'center md-left-align'
                      } pr2 ${
                        theme.subheadColor
                      } font-weight-100 h00 line-height-1 bignumber`}>
                      {col.bigNumber}
                    </figure>
                  ) : null}

                  <div
                    className={`${
                      col.bigNumber || !col.image ? 'pt1' : ''
                    } md-col-12 md-pt0`}>
                    <H4 color={theme.subheadColor} mb={0} mt={0} lineHeight={2}>
                      {col.subhead}
                    </H4>
                    <p className="mt1 mb0">{col.text}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </BlockWrapper>
  )
}

ThreeColumn.defaultProps = {
  settings: {
    showImages: false,
    showImagesStylized: false,
  },
}

ThreeColumn.propTypes = {
  sectionHeading: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      bigNumber: PropTypes.number,
      subhead: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      image: PropTypes.object,
    }).isRequired
  ).isRequired,
  settings: PropTypes.shape({
    showImages: PropTypes.bool,
    showImagesStylized: PropTypes.bool,
  }).isRequired,
}

const TwoColumn = props => {
  // ACF field limit is 600
  let longText = props.content.text && props.content.text.length >= 500

  return (
    <div
      className={`bg-${props.settings.bg} ${props.settings.color} flex flex-wrap md-flex-nowrap`}>
      <div className="col-12 md-col-6">
        <ImgSharp {...props.image} />
      </div>
      <div className="TwoColumn-right col-12 md-col-6 p2 md-px3 md-px3 lg-px4 self-center">
        <div className="TwoColumn-content max-width-3 mx-auto">
          <H3 sm="h5" md="h5" lineHeight={2} fontWeight={600} mt={0}>
            {props.content.subhead}
          </H3>
          <p className={`h5 ${longText ? 'md-h6' : 'md-h5'} line-height-4`}>
            {props.content.text}
          </p>
          {props.content.callToAction ? (
            <div className="mt2 mb1">
              <Button
                color={props.settings.color}
                to={urlAdjustments(props.content.callToAction.url)}>
                {unesc(props.content.callToAction.title)}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

TwoColumn.defaultProps = {
  settings: {
    bg: 'navy',
    color: 'white',
  },
}

TwoColumn.propTypes = {
  image: PropTypes.object.isRequired,
  settings: PropTypes.shape({
    color: PropTypes.string.isRequired,
    bg: PropTypes.string.isRequired,
    sticky: PropTypes.bool,
  }).isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    callToAction: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
}

const PartnerLogos = props => {
  if (!props.partnerLogos || !props.partnerLogos.length) {
    return null
  }

  return (
    <BlockWrapper bg="transparent" maxWidth={6} py={false}>
      <div className="mxn1 sm-mxn2 pb2 sm-pb3 lg-pb3">
        <ul className="list-style-none m0 p0 flex flex-wrap md-flex-wrap-none items-center">
          {props.partnerLogos.map((logo, index) => {
            return (
              <li
                className="select-none p1 sm-p2 col-6 sm-col-4 md-col-2"
                key={`${props.keyPrefix}_PartnerLogo_${index}`}>
                <div>
                  <ImgSharp {...logo} />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </BlockWrapper>
  )
}

PartnerLogos.defaultProps = {
  sectionHeading:
    'We partner with national corporations, leading public and private institutions, and regional facilities.',
}

PartnerLogos.propTypes = {
  sectionHeading: PropTypes.string,
  partnerLogos: PropTypes.array.isRequired,
}

const Text = props => {
  return (
    <BlockWrapper namespace="MainBlocksText" maxWidth={props.maxWidth}>
      {/*
      {props.sectionHeading ? (
        <SectionHeadingOnly
          mxAuto={true}
          maxWidth={props.maxWidth}
          text={props.sectionHeading}
        />
      ) : null}
      */}
      <div
        dangerouslySetInnerHTML={{
          __html: props.text,
          // .replace('<p><b>', '<h4>')
          // .replace('</b></p>', '</h4>'),
        }}
      />
    </BlockWrapper>
  )
}

Text.defaultProps = {
  maxWidth: 3,
}

Text.propTypes = {
  maxWidth: PropTypes.oneOf([1, 2, 3, 4, 5]),
  sectionHeading: PropTypes.string,
  text: PropTypes.string.isRequired,
}

const Project = props => {
  return (
    <div className="relative">
      {props.project.map((proj, index) => {
        let featuredImage =
          proj.featuredImagePortrait &&
          proj.featuredImagePortrait.featuredMediaPortrait
            ? proj.featuredImagePortrait.featuredMediaPortrait
            : proj.featuredImage
            ? proj.featuredImage.node
            : null

        return (
          <div className="md-sticky top-0 bg-navy">
            <TwoColumn
              settings={{
                bg: index % 2 === 0 ? 'navy' : 'darken-2',
                color: 'white',
              }}
              image={featuredImage}
              content={{
                subhead: proj.title,
                text: proj.details.description,
                callToAction:
                  proj.projectDetails.projectStatus === 'completed'
                    ? {
                        title: proj.title,
                        url: urlAdjustments(proj.uri),
                      }
                    : null,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export {
  BlockWrapper,
  SectionHeadingOnly,
  SectionHeading,
  TwoColumn,
  ThreeColumn,
  PartnerLogos,
  Text,
  Image,
  Testimonials,
  Project,
  Gallery,
}
