import React from 'react'
import PropTypes from 'prop-types'

// Map alignment props Basscss classes
const alignClasses = {
  left: 'left-align',
  right: 'right-align',
  center: 'center',
}

const headingClasses = function (props) {
  return [
    `line-height-${props.lineHeight}`,
    props.color ? props.color : '',
    `font-weight-${props.fontWeight}`,
    alignClasses[props.textAlign],
    `mt${props.mt}`,
    `mb${props.mb}`,
  ].join(' ')
}

const headingsSizes = function (xs, sm, md, lg) {
  return `${xs} sm-${sm} md-${md} lg-${lg}`
}

let h = {
  defaultProps: {
    fontWeight: 400,
    lineHeight: 2,
    size: 1,
    textAlign: 'left',
    mt: 1,
    mb: 1,
  },
  propTypes: {
    color: PropTypes.string,
    fontWeight: PropTypes.number,
    lineHeight: PropTypes.number,
    size: PropTypes.number,
    textAlign: PropTypes.string,
    mt: PropTypes.number,
    mb: PropTypes.number,
  },
}

const getDefaultProps = function (obj) {
  let res = Object.assign({}, h.defaultProps, obj)
  return res
}

const H1 = props => {
  let Tag = props.is
  return (
    <Tag
      className={`${headingClasses(props)} ${headingsSizes(
        props.xs,
        props.sm,
        props.md,
        props.lg
      )}`}>
      {props.children}
    </Tag>
  )
}

H1.propTypes = h.propTypes
let defaultPropsH1 = getDefaultProps({
  is: 'h1',
  color: 'magenta',
  fontWeight: 100,
  lineHeight: 1,
  xs: 'h1',
  sm: 'h1',
  md: 'h1',
  lg: 'h1',
})
H1.defaultProps = defaultPropsH1

const HPage = props => {
  let Tag = props.is
  return (
    <Tag
      className={`${headingClasses(props)} ${headingsSizes(
        props.xs,
        props.sm,
        props.md,
        props.lg
      )}`}>
      {props.children}
    </Tag>
  )
}

HPage.propTypes = h.propTypes
let defaultPropsHPage = getDefaultProps({
  is: 'h1',
  color: 'magenta',
  fontWeight: 600,
  lineHeight: 1,
  mt: 2,
  mb: 2,
  xs: 'h3',
  sm: 'h3',
  md: 'h3',
  lg: 'h3',
})
HPage.defaultProps = defaultPropsHPage

const H2 = props => {
  let Tag = props.is
  return (
    <Tag
      className={`${headingClasses(props)} ${headingsSizes(
        props.xs,
        props.sm,
        props.md,
        props.lg
      )}`}>
      {props.children}
    </Tag>
  )
}

H2.propTypes = h.propTypes
let defaultPropsH2 = getDefaultProps({
  is: 'h2',
  lineHeight: 2,
  xs: 'h3',
  sm: 'h3',
  md: 'h3',
  lg: 'h3',
})
H2.defaultProps = defaultPropsH2

const H3 = props => {
  let Tag = props.is
  return (
    <Tag
      className={`${headingClasses(props)} ${headingsSizes(
        props.xs,
        props.sm,
        props.md,
        props.lg
      )}`}>
      {props.children}
    </Tag>
  )
}

H3.propTypes = h.propTypes
let defaultPropsH3 = getDefaultProps({
  is: 'h3',
  lineHeight: 4,
  fontWeight: 400,
  xs: 'h4',
  sm: 'h4',
  md: 'h4',
  lg: 'h4',
})
H3.defaultProps = defaultPropsH3

const H4 = props => {
  let Tag = props.is
  return (
    <Tag
      className={`${headingClasses(props)} ${headingsSizes(
        props.xs,
        props.sm,
        props.md,
        props.lg
      )}`}>
      {props.children}
    </Tag>
  )
}

H4.propTypes = h.propTypes
let defaultPropsH4 = getDefaultProps({
  is: 'h4',
  lineHeight: 4,
  fontWeight: 600,
  xs: 'h5',
  sm: 'h5',
  md: 'h5',
  lg: 'h5',
})
H4.defaultProps = defaultPropsH4

export { H1, H2, H3, H4, HPage }
