import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const ImgSharp = props => {
  let { localFile, altText, useGatsbyImage, ...remainder } = props

  if (!localFile || !localFile.childImageSharp) {
    return null
  }

  let sharp = localFile.childImageSharp

  if (sharp.resize) {
    useGatsbyImage = false
    remainder.src = sharp.resize.src
  }

  if (useGatsbyImage === false) {
    if (localFile.childImageSharp.fluid) {
      remainder.src = localFile.childImageSharp.fluid.src
      remainder.srcSet = localFile.childImageSharp.fluid.srcSet
    } else if (localFile.childImageSharp.fixed) {
      remainder.src = localFile.childImageSharp.fixed.src
      remainder.srcSet = localFile.childImageSharp.fixed.srcSet
    }
    return <img alt={altText} {...remainder} />
  }

  return <Img alt={altText} {...sharp} {...remainder} />
}

ImgSharp.propTypes = {
  altText: PropTypes.string.isRequired,
  localFile: PropTypes.object.isRequired,
  className: PropTypes.string,
  useGatsbyImage: PropTypes.bool.isRequired,
}

ImgSharp.defaultProps = {
  altText: '',
  className: 'block m0 col-12',
  useGatsbyImage: true,
}

export default ImgSharp
